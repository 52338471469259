(function($) {

Drupal.behaviors.ELB_BeautyChat = {
  attach: function(context, settings) {
    var $tray = $('.beauty-chat-tray', context);
    var site = site || {};
    // @TODO: need to connect to 3rd party chat service
    site.beautyChatAvailable = site.beautyChatAvailable || true;

    $tray.toggleClass('beauty-chat-tray--unavailable', !site.beautyChatAvailable);

    $('.beauty-chat-drawer .drawer-formatter__trigger', context).on('click', function() {
	  lpMTagConfig.delegate.prechatWindow();
	});
  }
};

})(jQuery);
